// src/pages/resources/videos/signia-bte.js

import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb';
import VideoCategoryNav from '../../../components/VideoCategoryNav';
import { useLocation } from '@reach/router';

const SigniaBTE = ({ pageContext }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const [videoCount, setVideoCount] = useState(6);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const location = useLocation();

	const videos = [
		{
			id: '70imervmS4c',
			url: 'https://www.youtube.com/embed/70imervmS4c?si=n07n_tuaerPG33It',
			title: 'How to turn Pure Charge&Go AX on and off',
			subtitle: 'Follow these steps to learn how to turn your Pure Charge&Go AX RIC hearing aids on and off.'
		},
		{
			id: 'EeKoIrOKxvA',
			url: 'https://www.youtube.com/embed/EeKoIrOKxvA?si=CQjunhIg5GdOAOkU',
			title: 'How to insert Pure Charge&Go AX in the ear',
			subtitle: 'Learn how to insert a Pure Charge&Go AX Receiver-in-canal (RIC) hearing aid in your ear.'
		},
		{
			id: 'Y_-2HOv3M90',
			url: 'https://www.youtube.com/embed/Y_-2HOv3M90?si=RmSMDxPQPVbgp1IE',
			title: 'How to charge Pure Charge&Go AX with the Pure Portable Charger',
			subtitle: 'Learn how to charge the Signia Pure Charge&Go AX receiver-in-canal hearing aids.'
		}
	];

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const videoId = params.get('videoID');
		if (videoId) {
			const video = videos.find((v) => v.id === videoId);
			setSelectedVideo(video);
		}
	}, [location]);

	const handleShowMore = () => {
		setVideoCount((prevCount) => prevCount + 6);
	};

	const visibleVideos = videos.slice(0, videoCount);

	return (
		<Layout>
			<Seo
				title="hear.com hearing aids instructional videos | hear.com"
				description="Watch videos on care, maintenance, cleaning, how-to guides, and testimonials for in-the-ear & behind-the-ear hearing aids by hear.com"
			/>
			<div className="o-wrapper">
				<div className="o-row-3p5"></div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<VideoCategoryNav />
				<div className="c-video-cards-wrapper o-row-3p5">
					{selectedVideo ? (
						<div className="c-video-container">
							<iframe
								className="c-video-card"
								title={selectedVideo.title}
								src={selectedVideo.url}
								allow="accelerometer; autoplay; picture-in-picture"
								allowFullScreen
							></iframe>
							<p className="c-video-title">{selectedVideo.title}</p>
							<p className="c-video-subtitle">{selectedVideo.subtitle}</p>
						</div>
					) : (
						visibleVideos.map((video, index) => (
							<div className="c-video-container" key={index}>
								<iframe
									className="c-video-card"
									title={video.title}
									src={video.url}
									loading={index < 6 ? 'eager' : 'lazy'}
									allow="accelerometer; autoplay; picture-in-picture"
									allowFullScreen
								></iframe>
								<p className="c-video-title">{video.title}</p>
								<p className="c-video-subtitle">{video.subtitle}</p>
							</div>
						))
					)}
				</div>
				{videos.length > videoCount && (
					<div className="c-show-more-wrapper">
						<button className="c-show-more-btn" onClick={handleShowMore}>
							Show More
						</button>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default SigniaBTE;
